import * as React from 'react';
import { graphql } from 'gatsby';
import DefaultHeaderMenu from '../components/defaultHeaderMenu/defaultHeaderMenu';
import Footer from '../components/footer/footer';
import GlobalStyles from '../styles/globalStyles';
import {
  HtmlSitemapMainDiv,
  HtmlSiteMapHeader,
  HtmlSitemapTitle,
} from '../styles';
import BootstrapNavbar from '../components/navigationBar/mainMenu/navigationBar';
import { addTrailingSlash } from '../utils';
import { Helmet } from 'react-helmet';

const HtmlSitemap = ({ data, location }) => {
  const path = location?.pathname?.split('/')[1];

  const allSlugs = [
    ...data?.allContentfulChevron?.edges
      ?.filter((edge) => {
        if (
          edge?.node?.slug &&
          !edge?.node?.slug?.includes('modal') &&
          !edge?.node?.slug?.includes('404')
        ) {
          return edge?.node?.slug;
        }
      })
      .map((edge) => {
        return {
          slug: addTrailingSlash(
            process.env.GATSBY_DOMAIN_URL + edge?.node?.slug
          ),
          title: edge?.node?.title,
        };
      }),
  ];

  const flags = [],
    l = allSlugs.length;
  let SlugList = [];
  for (let i = 0; i < l; i++) {
    if (flags[allSlugs[i].slug]) {
      continue;
    }
    flags[allSlugs[i].slug] = true;
    SlugList.push(allSlugs[i]);
  }
  SlugList = SlugList.sort((a, b) => a?.slug?.localeCompare(b?.slug));

  return (
    <>
      <Helmet>
        <title>Parkland | Chevron :: Site Map</title>
      </Helmet>
      <GlobalStyles />
      <DefaultHeaderMenu />
      <BootstrapNavbar activeLink={path} />
      <HtmlSitemapMainDiv>
        {SlugList.map(({ slug, title }, index) => (
          <HtmlSitemapTitle key={index}>
            <HtmlSiteMapHeader
              href={slug}
              $leftSpace={slug?.split('/')?.length - 1}
            >
              {title ?? slug}
            </HtmlSiteMapHeader>
          </HtmlSitemapTitle>
        ))}
      </HtmlSitemapMainDiv>
      <Footer />
    </>
  );
};

export default HtmlSitemap;
export const pageQuery = graphql`
  query SitemapQuery {
    allContentfulChevron(filter: { node_locale: { eq: "en-US" }, hasIndex: { ne: false } }) {
      edges {
        node {
          slug
          title
          name
        }
      }
    }
  }
`;
